.ats-my-tickets {
  #ats-category-filters .col-auto:first-child {
    min-width: 250px;
  }
}

.js-editor-tinymce .toggle-editor {
  display:none;
}


.choices__button_joomla::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  text-align: center;
  text-indent: 0;
  content: "×";
}
.choices__button_joomla {
  position: relative;
  padding: 0 10px;
  color: inherit;
  text-indent: -9999px;
  cursor: pointer;
  background: none;
  border: 0;
  opacity: .5;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
