.docimport {
  .revhistory {
    table {
      border-width: 0 !important;
    }
  }

  .informalfigure,
  .figure {
    margin: 1em 0;
  }
}

.docimport-category-index {
  .author {
    display: none;
  }
  table {
    border: 0;
    td:nth-child(1) {
      text-align: right;
      vertical-align: middle;
    }
    td:nth-child(2),
    td:nth-child(3),
    {
      text-align: center;
      vertical-align: middle;

      img {
        border-width: 0;
      }
    }
  }
}

.table-contents {
  table {


    --bs-table-bg: rgba(0, 0, 0, 0);
    --bs-table-accent-bg: rgba(0, 0, 0, 0);
    --bs-table-striped-color: #212529;
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: #212529;
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: #212529;
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    vertical-align: top;
    border-color: #dee2e6;

    td {
      padding: 0.5rem 0.5rem;
      background-color: var(--bs-table-bg);
      border-bottom-width: 1px;
      box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    }
  }


}

@media (prefers-color-scheme: dark) {
  @import 'com_docimport_dark';
}
