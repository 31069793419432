.navbar {

  .navbar-collapse {
    justify-content: space-between;
  }

  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0.125rem;
  }

  #navbarSupportedContent {
    align-items: flex-start;
  }

  .menu {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    @media (min-width: 992px) {
        display: block;
          margin-top: 0;
    }

    .nav-item a {
      text-transform: uppercase;
      font-size: calc(var(--bs-body-font-size) * 1.2);
    }

    .nav-item.active a {
      font-weight: bold;
    }

    .sub .nav-item a {
      text-transform: none;
      font-size: calc(var(--bs-body-font-size));
    }
  }

  div.mod-languages ul li.lang-active {
    background-color: transparent;
  }

  div.mod-languages li {
    margin: 0 0 0.5em 0.5em;
    a {
      padding: 0;
    }
  }
}

