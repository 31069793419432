.blog-items {
  &.columns-2 {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2em;
    .blog-item {
      @media (min-width: 992px) {
        flex-basis: calc(50% - 1em);
      }
    }
  }

  .item-content {
    margin: 1em 0;
    display: flow-root;

    .btn {
      margin: 1em 0;
    }

    .article-info-term {
      display: none;
    }

    .create {
      border-bottom: 1px dotted $dark;
    }
  }
}

.com-content-article.item-page  {
  .article-info {
    display: none;
  }

  hr {
    clear:both;
    visibility: hidden;
  }

  img.smartresize {

    @media (min-width: 300px) {
      width: calc(50% - 0.5rem - 1em);
    }


    @media (min-width: 600px) {
      width: calc(33% - 0.5rem - 1em);
    }

    @media (min-width: 992px) {
      width: calc(25% - 0.5rem - 1em);
    }
  }


}

table.compare {
  td.center {
    text-align: center;
  }
 .icon-check {
   color: $green-500;
 }
  .icon-cancel {
    color: $red-500;
  }
}

img.smartresize {
  float: left;
  padding: $thumbnail-padding;
  margin-right: 1em;
  margin-bottom: 1em;
  background-color: $thumbnail-bg;
  border: $thumbnail-border-width solid $thumbnail-border-color;
  @include border-radius($thumbnail-border-radius);
  @include box-shadow($thumbnail-box-shadow);

  // Keep them at most 100% wide
  @include img-fluid();

  &:hover {
    border: 1px solid $blue-100;
  }
}

.com-content-category-blog__children.cat-children {
  display:none;
}
