.com-finder {
  #search-result-list {

    li {
      .result__image {
        border-width: 0;
        padding: 0;
      }
      .result__title-url {
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
    }
  }
}
