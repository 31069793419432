.jumbotron {
  padding: 80px 0;
  text-align: center;
  word-break: break-word;

  @media (max-width: 500px) {


    .btn-group {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    .btn-group > .btn {
      border-radius: 0;
      width: 100%;
      margin:0;

      &:first-child {
        border-top-left-radius: 0.25em;
        border-top-right-radius: 0.25em;
      }
      &:last-child {
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
    }
  }
}
