// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

// Layout & components
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
//@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
//@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
//@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
//@import "bootstrap/scss/tooltip";
//@import "bootstrap/scss/popover";
//@import "bootstrap/scss/carousel";
//@import "bootstrap/scss/spinners";
//@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";
// scss-docs-end import-stack

@import "tools/variables/variables";
@import "blocks/form";

@import "fonts";
@import "header";
@import "footer";
@import "legacy";
@import "eventgallery";
@import "com_content";
@import "com_ats";
@import "com_docimport";
@import "com_finder";

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

hr.invisible {
  clear:both;
}

.pagination {
  flex-wrap: wrap;
}


nav.top-menu,
main,
footer {
  padding: 10px;
  max-width:1280px;
  margin: auto;
}

nav.breadcrumbs ol{
  padding: 0 !important;
}

main {
  margin-top: 10px;
  margin-bottom: 10px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (min-width: 992px) {
    flex-wrap: nowrap;
  }

  .column-content {
    width: 100%;
    .card {
      margin-bottom: 1em;
    }
    .card.well {
      background-color: $light;
    }
  }

  aside {
    width: 100%;
    @media (min-width: 992px) {
      margin-left: 1em;
      flex: 0 0 250px;
    }

    .card {
      margin-bottom: 1em;

      .card-header {
        font-size: calc(var(--bs-body-font-size)*0.8);
        text-transform: uppercase;
      }

      a {
        color: $navbar-light-color;
        text-decoration:none;
        :hover {
          color: $navbar-light-hover-color;
        }
      }

      a.btn {
        color: $white;
      }

      .mod-login__submit {
        margin: 1em 0;
      }
    }
  }
}

#mod_mptaxcountry_form {
  padding: 1em;
}
