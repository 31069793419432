footer {
  .linklists {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    @media (min-width: 992px) {
      flex-wrap: nowrap;
    }

    h3 {
      font-size: calc(var(--bs-body-font-size));
      text-transform: uppercase;
      color: $navbar-light-color;
      font-weight: bold;
    }

    ul {
      display: block;
      margin: 0 0 1em 0;
      padding: 0;
    }

    li {
      list-style: none;
      padding: 0.5rem 1rem;
    }

    a {
      text-decoration: none;
      font-size: calc(var(--bs-body-font-size)*0.9);
      padding: 0;
      color: $navbar-light-color;
      :hover {
        color: $navbar-light-hover-color;
      }
    }
  }
}

